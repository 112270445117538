<template>
  <div class="flex-row align-items-center">
    <div class="container">
      <div class="row">
        <div class="col"><label class="h2">1. Hướng dẫn đăng ký mới user và kích hoạt tài khoản </label></div>
      </div><br/>
      <div class="row">
        <div class="col h5">
          Hình 1: Nhập thông tin như yêu cầu (những ô tô đỏ) và nhấp nút "Submit"
          <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/register%2F1.png?alt=media&token=cca288fa-a0ca-41dd-b352-d7de906d3dd9">
        </div>
      </div><br/>

      <div class="row">
        <div class="col h5">
          Hình 2: Hệ thống sẽ tiến hành đăng ký và trả về màn hình bên dưới
          <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/register%2F4.png?alt=media&token=09bb2000-fe2f-42a3-94dd-3e79c705a3f0">
        </div>
      </div><br/>

      <div class="row">
        <div class="col h5">
          Hình 3: Vào email nhấp vào đường dẫn, để kích hoạt tài khoản
          <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/register%2F5.png?alt=media&token=3591bf87-710e-45e2-a61d-652c5f2aeef5">
        </div>
      </div><br/>

      <div class="row">
        <div class="col h5">
          Hình 4: Kích hoạt thành công hệ thống trả về màn hình bên dưới
          <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/register%2F6.png?alt=media&token=7161e92f-95f5-45e2-ae48-0dbcc27669d0">
        </div>
      </div><br/>
      
      <div class="row">
        <div class="col h5">
          Hình 5: Bạn đã đăng ký thành công tài khoản và quay lại màn hình đăng nhập
          <img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/register%2F7.png?alt=media&token=40141a02-6595-41cd-8e94-caa3bfdf2176">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  components: {},
    data () {
      return {
      }
    },
    async mounted () {
      debugger
      let app=this
      let code=app.$route.query.code
      if(!code) return

      app.code=code
      let res= await app.confirmEmail(code)
      if(res && res.data && res.data.ok){
        debugger
        let user_db=res.data.user
        app.show=true
      }else{
        this.token=null
        app.$store.commit('st_authentication/login_failure')
      }
    },
    methods: {
      async confirmEmail(token){
        return await this.$store.dispatch(`st_user/confirm_email`,token)
      }
    }
}
</script>
